


import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";
import DisplayFiles from "@/views/DisplayDocument.vue";
import { Multiselect } from "vue-multiselect";
import { ALLOWED_IMAGES_EXTENSIONS } from "@/constants";

@Component({components: {DisplayFiles,Multiselect} })
export default class Management extends Vue {
  public allowedFileExtensions = ALLOWED_IMAGES_EXTENSIONS;
  public lender : any = {};
  public inProcess = false;
  public defaultTitle = "Add Lender";
  public defaultSubmitName = "Add";
  public disableAddButton = false;
  public lenderLoader = false;
  public loadMoreScroll = true;
  public skip = 0;
  public currentLengthOnScroll = 0;
  public searchString = null;
  public lenderList: any = "";
  public totalCount = 0;
  public id = null;
  public alphaProfile = "";
  public file: any = [];
  public vendorList: Array<any>=[]
  public imageCordinates = {
    height: 0,
    width: 0
  };
  public countViewedUsers = 0;
  public limit = 20;
 
  public async validateAndSubmit() { 
    try {
      this.disableAddButton = true;
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        this.disableAddButton = false;
        return;
      }

    /**
     *  append data in form data
     */
      var formData = new FormData();
      if(this.lender.files){
        formData.append("file", this.lender.files[0]);
      }

      formData.append("lenderMortgageeClause", this.lender.lenderMortgageeClause);
      formData.append("userType", this.$store.state.sessionObject.type); 
      formData.append("lenderName", this.lender.lenderName);
      formData.append("lenderWebsite", this.lender.lenderWebsite);
      formData.append("addedBy", this.$userId);
      formData.append("isIntegrated",  this.lender.isIntegrated || false);

      /**
       *  manage condition for add and edit
       */
        if (this.defaultSubmitName == "Add") { 
          let response = await Axios.post(
            BASE_API_URL + "superAdmin/addLender",
            formData);
          
          if (response.status == 200) {
            this.$snotify.success("Successfully Added");
        
            this.defaultFuntion();
          }
        } else {
          formData.append("amcLinked", JSON.stringify(this.lender.amcLinked));
          formData.append("lenderId", this.lender._id);
          let response = await Axios.post(
            BASE_API_URL + "superAdmin/updateLender",
            formData);
          if (response.status == 200) {
            this.$snotify.success("Successfully Updated");
            this.defaultFuntion();
          }
        }
this.getLenderList(false)        

      
      this.disableAddButton = false;
    } catch (error) {
      this.disableAddButton = false;
    }
  }

/**
 *  Get Lender details
 */
  public async getLenderList(mountCall) {
    try {
      let body = {
        skip: this.skip,
        limit: this.limit,
        searchString: this.searchString,
      }

      if (mountCall) {
        this.lenderLoader = true;
      }
      const response = await Axios.post(
        BASE_API_URL + "superAdmin/getLender",
      body);

      this.vendorList = response.data?.amcList;

      this.lenderList = mountCall ? response.data.lenderDetails : this.lenderList.concat(response.data.lenderDetails);

      this.currentLengthOnScroll = this.lenderList.length;
      this.skip += this.limit;
      await this.loadProfilePictures();
    } catch (error) {
      console.error(error);
    } finally {
      this.loadMoreScroll = false;
      this.lenderLoader = false;
    }
  }

  public async getTotalLenderCount() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getLender",
        {
          skip: 0,
          limit: 0,
          searchString: "",
        });
      this.totalCount = response.data.totalCount;
    } catch (error) {
      console.log(error);
    }
  }

  public async showMoreDataOnScroll() {
    if (!this.loadMoreScroll && this.currentLengthOnScroll < this.totalCount) {
      this.loadMoreScroll = true;
      await this.getLenderList(false);
      await this.loadProfilePictures();
    }
  }


public async editCount(data) 
  {
    this.$validator.reset();
    this.defaultTitle = "Edit Lender";
    this.defaultSubmitName = "Update";
    this.lender = Object.assign({}, data);
    if(this.lender.profilePicture!=null){
      this.loadImagew(this.lender.profilePicture)
      }
    this.$store.state.displayFiles.fileToDisplay = null;

  }

public async defaultFuntion() {
    this.$validator.reset();
    this.lender = {};
    this.defaultTitle = "Add Lender";
    this.defaultSubmitName = "Add";
    this.alphaProfile = "";
    await this.getLenderList(true);
    this.$store.state.displayFiles.fileToDisplay = null;
    this.$store.state.displayFiles.fileType = null;
  }

public openConfirmationModal(id) {
    try {
      this.id = id;
      this.$modal.show("confirmDeleteFileModal");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *  delete lender info
   */
  public async deleteLender(id) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/deleteLender",
        {
          id: id
        });
      this.$snotify.success("Data has been deleted successfully");
      this.$modal.hide("confirmDeleteFileModal");
      this.defaultFuntion();
    } catch (error) {
      console.log(error);
    }
  }


  public async uploadFile(e){
     var files = e.target.files;
     this.lender.files = files;
     this.file = [];
     if (e.target.files) {
       Object.values(e.target.files).forEach(element => {
         this.file.push(element);
       });
     }
   
      this.$refs.lc["setInitialValuesForLocalFiles"](this.file, null, null);

      if(this.$store.state.displayFiles.fileToDisplay){
        this.lender.profilePicture = null;
      }
}

  public getFirstLetter(lenderName){
     return lenderName.charAt(0);

   }

  private async loadImage(path, i){
    try {
      const response = await Axios.post(BASE_API_URL + 'common/fetchDocument', { path: path, hideError: true });
      if (!response.data) {
        this.setLetterInsteadAvatar(i);
      } else {
        const dom = document.getElementById('imageEl' + i);
        const img = document.createElement('img');
        img.src = 'data:image/' + path.split('.').pop() + ';base64,' + response.data;
        img.className = 'broker-list-profile cursor-pointer';
        img.id = 'img-id'+i;
        dom.innerHTML = "";
        dom.appendChild(img);
      }
    } catch (error) {
      this.setLetterInsteadAvatar(i);
      console.log(error);
    }
  }

  public setLetterInsteadAvatar(index) {
    if (this.lenderList[index]){
      let letter = this.getFirstLetter(this.lenderList[index].lenderName);
      let dom = document.getElementById('imageEl' + index);
      dom.innerHTML = `<span v-else class='superadmin-processor-icon'> ${letter} </span>`;
    }
  }

   private async loadImagew(path){
    try {
      let response = await Axios.post(BASE_API_URL+'common/fetchDocument', { path: path });
      let dom = document.getElementById('lenderPic')

      dom['src'] = 'data:image/'+path.split('.').pop()+';base64,'+response.data;
      dom.append();
    } catch (error) {
      console.log(error);
    }
  }

get profilePicture() {
    if (
      Object.keys(this.lender).length > 0 &&
      this.lender.profilePicture
    ) {   
      let arr = this.lender.profilePicture.split(".");
      let splitSrc = arr[0].split("_") 
      let ext = arr.pop();
      let src = splitSrc[0] + '_';
      return src + "small." + ext;
    } else {
      if (Object.keys(this.lender).length > 0) {
        this.alphaProfile = this.lender.lenderName
          .slice(0, 1)
          .toUpperCase();
      }
      return null;
    }
  }

  public setCordinatesOfImage(url) {
    var image = new Image();
    image.src = url;
    image.onload = () => {
      if (image.height >= image.width) {
        this.imageCordinates.width = image.width / 3;
        this.imageCordinates.height = image.height / 3;
      } else {
        this.imageCordinates.width = image.width / 2;
        this.imageCordinates.height = image.height / 2;
      }
    };
  }

  public async loadProfilePictures() {
    let promises = [];
    for (let indx = this.countViewedUsers; indx < this.lenderList.length; indx++) {
      const lenderDetail = this.lenderList[indx];
      if(lenderDetail.profilePicture) promises.push(this.loadImage(lenderDetail.profilePicture, indx));
    }
    this.countViewedUsers = this.lenderList.length;
    if(promises.length) await Promise.all(promises)
  }
  
  async mounted() {
    await this.getTotalLenderCount();
    await this.getLenderList(true);
  }



}
