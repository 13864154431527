import { render, staticRenderFns } from "./WemloLender.html?vue&type=template&id=040267d0&"
import script from "./WemloLender.vue?vue&type=script&lang=ts&"
export * from "./WemloLender.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports